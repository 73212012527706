import axios from "axios";

const storage = JSON.parse(localStorage.getItem("persist:root")!);
const user = storage !== null && JSON.parse(storage.userReducer).currentUser;
//console.log(user.body.token)
export const TokenHeader = user.body?.token;
// const accessToken = JSON.parse(localStorage.getItem('access_token')!) || null;

export const client = (token = null) => {
  // console.log('inside client',token)

  const defaultOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  };

  return {
    get: async (url: string, options = {}) =>
      await axios.get(url, { ...defaultOptions, ...options }),
    post: async (url: string, data = {}, options = {}) =>
      await axios.post(url, data, { ...defaultOptions, ...options }),
    put: async (url: string, data = {}, options = {}) =>
      await axios.put(url, data, { ...defaultOptions, ...options }),
    delete: async (url: string, options = {}) =>
      await axios.delete(url, { ...defaultOptions, ...options }),
    patch: async (url: string, data = {}, options = {}) =>
      await axios.patch(url, data, { ...defaultOptions, ...options }),
  };
};
