import React, { createContext, useContext, useState } from "react";
import compareArrays from "../../../utils/compareArrays";
import { handleFetch } from "./customFetch";
// import { IDeviceData } from "./../../../types/device";
interface IDeviceChannels {
  imei: string;
  channelId: number[];
  deviceName: string;
  streamId?: string;
  token?: string;
}

type VideoLeftCtxTypes = {
  columns: number;
  setColumns: React.Dispatch<React.SetStateAction<number>>;
  activeChannels: IDeviceChannels[];
  // handleAllChannels: () => void;
  handleAllChannels: (
    imei: string,
    channelIds: number[],
    deviceName: string
  ) => void;
  isChannelActive: (imei: string, channelId) => boolean;
  handleToggleChannel: (imei, channelId, deviceName) => void;
  handlePlay: ({ imei, channelId }) => void;
  setActiveChannels: React.Dispatch<React.SetStateAction<IDeviceChannels[]>>;
};

const defaultContextValue: VideoLeftCtxTypes = {
  columns: 1,
  setColumns: () => {},
  activeChannels: [],
  setActiveChannels: () => {},
  handleAllChannels: () => {},
  isChannelActive: () => false,
  handleToggleChannel: () => {},
  handlePlay: () => {},
};

export const VideoLeftCtx =
  createContext<VideoLeftCtxTypes>(defaultContextValue);
export const useDeviceVideo = () => {
  return useContext(VideoLeftCtx);
};
export const VideoProvider = ({ children }: { children: React.ReactNode }) => {
  const [columns, setColumns] = useState<number>(1);
  const [activeChannels, setActiveChannels] = useState<IDeviceChannels[]>([]);

  /////
  const handleAllChannels = (imei, channelIds, deviceName) => {
    const isChannelActive = activeChannels.filter(
      (channel) =>
        channel.imei === imei && compareArrays(channelIds, channel.channelId)
    );

    if (isChannelActive.length === 0) {
      const newChannel = {
        channelId: channelIds,
        imei,
        deviceName,
      };

      setActiveChannels((prev) => [newChannel, ...prev]);
    } else {
      setActiveChannels((prev) => {
        return prev.filter((channel) => channel.imei !== imei);
      });
    }
  };

  const isChannelActive = (imei: string, channelId) => {
    return activeChannels.some(
      (channel) =>
        channel.imei === imei && channel.channelId.includes(channelId)
    );
  };

  const handleToggleChannel = (imei, channelId, deviceName) => {
    const isDeviceActive = activeChannels.filter(
      (channel) => channel.imei === imei
    );

    if (isDeviceActive.length === 0) {
      const newChannel = {
        channelId: [channelId],
        imei,
        deviceName,
      };

      setActiveChannels((prev) => [newChannel, ...prev]);
    }
    if (isDeviceActive.length > 0) {
      const device = isDeviceActive[0];
      const isChannelAreadyChecked = isChannelActive(device.imei, channelId);
      let newChannel;
      if (isChannelAreadyChecked) {
        newChannel = {
          imei: device.imei,
          deviceName,
          channelId: device.channelId.filter(
            (channel) => channel !== channelId
          ),
        };
      } else {
        newChannel = {
          imei: device.imei,
          deviceName,
          channelId: [channelId, ...device.channelId],
        };
      }
      console.log(newChannel, isChannelAreadyChecked);
      setActiveChannels((prev) => {
        return [newChannel, ...prev.filter((channel) => channel.imei !== imei)];
      });
    }

    // else {
    //   setActiveChannels((prev) => {
    //     return prev.filter(
    //       (channel) =>
    //         channel.imei !== imei &&
    //         !channel.channelId.includes(channelId)
    //     );
    //   });
    // }
  };
  const handlePlay = async function ({ imei, channelId }) {
    try {
      const body = {
        imei: "1111110111",
        channel: 1,
      };
      const data = await handleFetch(
        process.env.REACT_APP_MONITOR_URL + "video/streams/live",
        "POST",
        body
      );
      console.log(data);
      setActiveChannels((prev) =>
        prev.map((deviceChannel) => {
          if (deviceChannel.imei === imei) {
            return {
              ...deviceChannel,
              token: data.data.token,
              streamId: data.data.streamId,
            };
          }
          return {
            ...deviceChannel,
          };
        })
      );
    } catch (err) {}
  };
  return (
    <VideoLeftCtx.Provider
      value={{
        columns,
        setColumns,
        activeChannels,
        setActiveChannels,
        handleAllChannels,
        handleToggleChannel,
        isChannelActive,
        handlePlay,
      }}
    >
      {children}
    </VideoLeftCtx.Provider>
  );
};
