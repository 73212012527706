export default function compareArrays(arr1, arr2) {
  // Check if the lengths are different
  if (arr1.length !== arr2.length) return false;

  // Sort both arrays
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  // Compare each element
  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}
