export const handleFetch = async function (url, method, body) {
  const token = JSON.parse(localStorage.getItem("access_token")!);
  try {
    const options = {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: method !== "GET" ? JSON.stringify(body) : undefined,
    };

    const response = await fetch(url, options);

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || response.statusText || "Request failed");
    }

    return await response.json();
  } catch (err) {
    throw new Error(err.message || "Network error");
  }
};
